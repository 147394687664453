import { get as getNotification, markAsSeen } from 'hcms-transforms/notifications';

import BookingDetails from '@/views/Booking/BookingDetails.vue';
import BookingList from '@/views/Booking/index.vue';
import Roster from '@/views/EngineRun.vue';
import NewLeave from '@/views/Leave/NewLeave.vue';
import Leave from '@/views/Leave/index.vue';
import Availability from '@/views/Misc/Availability.vue';
import AvailabilityChange from '@/views/Misc/AvailabilityChange.vue';
import AvailabilityRequest from '@/views/Misc/AvailabilityRequest.vue';
import Contacts from '@/views/Misc/Contacts.vue';
import Preferences from '@/views/Misc/Preferences.vue';
import Trainings from '@/views/Misc/Trainings.vue';
import Misc from '@/views/Misc/index.vue';
import Notifications from '@/views/Notifications.vue';
import Overview from '@/views/Overview/index.vue';
import EmptyRouterView from 'layouts/Empty.vue';
import { getRouteObject } from 'utils/notificationUtils';
import { notify } from 'utils/notify';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '',
    redirect: '/overview',
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
  },
  {
    path: '/bookings',
    name: 'Booking List',
    component: BookingList,
  },
  {
    path: '/booking/:bookingId',
    component: EmptyRouterView,

    children: [
      {
        path: 'details',
        name: 'Booking Details',
        props: (route) => ({
          bookingId: Number(route.params.bookingId),
        }),
        component: BookingDetails,
      },
    ],
  },
  {
    path: '/leave',
    name: 'Leave',
    component: Leave,
  },
  {
    path: '/availability',
    name: 'Availability',
    component: Availability,
  },
  {
    path: '/availability-change',
    name: 'Change Availability',
    component: AvailabilityChange,
  },
  {
    path: '/availability-request',
    name: 'Availability Request',
    component: AvailabilityRequest,
  },
  {
    path: '/apply-leave',
    name: 'New Leave',
    component: NewLeave,
  },
  {
    path: '/more',
    name: 'Misc',
    component: Misc,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
  },
  {
    path: '/preferences',
    name: 'Preferences',
    component: Preferences,
  },
  {
    path: '/training',
    name: 'Trainings',
    component: Trainings,
  },
  {
    path: '/training/:trainingId',
    name: 'Training',
    component: Trainings,
    props: (route) => ({
      trainingId: Number(route.params.trainingId),
    }),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
  },
  {
    path: '/past-notifications',
    name: 'Past Notifications',
    component: Notifications,
    props: {
      showPastNotifications: true,
    },
  },
  {
    path: '/acknowledge-notification/:notificationId',
    name: 'Acknowledge Notification',
    async beforeEnter(to, from, next) {
      if (!to.params.notificationId) {
        next({
          name: 'Notifications',
        });
        return;
      }
      try {
        const notification = await getNotification(to.params.notificationId);
        const route = await getRouteObject(notification);
        const baseRoute = { name: 'Notifications' };
        if (!notification.seenDate) {
          await markAsSeen(notification.id);
          notify('Notification has been acknowledged.', 'success');
        }
        if (typeof route === 'object') {
          next(route);
        } else if (typeof route === 'string') {
          window.open(route, '_blank');
          next(baseRoute);
        } else {
          next(baseRoute);
        }
      } catch (error) {
        notify(error, 'error');
        next({
          name: 'Notifications',
        });
      }
    },
  },
  {
    path: '/roster/:engineRunId',
    name: 'Roster',
    component: Roster,
    props: (route) => ({
      engineRunId: Number(route.params.engineRunId),
    }),
  },
];

export default new Router({
  routes,
  mode: 'history',
  linkActiveClass: 'active',
});
