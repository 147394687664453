import { getAll as getBookings } from 'hcms-transforms/booking';
import { get as getChangeRequest } from 'hcms-transforms/booking/change_request';
import { NEW_FORM_SUBMISSION } from 'hcms-constants/APP_PATHS';
import { currentUser } from 'auth-utils';

async function getRouteObject(notification) {
  const { id, type } = notification.action;
  let data;
  switch (type) {
    case 'Leave':
      return {
        name: 'Leave',
        params: {
          leaveId: id,
        },
      };

    case 'CwAvailability':
      return {
        name: 'Availability',
      };

    case 'Contact':
      return {
        name: 'Contacts',
        params: {
          contactId: id,
        },
      };
    case 'Training':
      return {
        name: 'Trainings',
        params: {
          trainingId: id,
        },
      };
    case 'HcmsCwPreference':
      return {
        name: 'Preferences',
      };

    case 'BookingAllocation':
      data = await getBookings({
        booking_allocation: id,
        care_worker_user_name: currentUser.userName,
      });
      return {
        name: 'Booking Details',
        params: {
          bookingId: data[0].bookingId,
        },
      };

    case 'BookingChangeRequest':
      data = await getChangeRequest(id);
      return {
        name: 'Booking Details',
        params: {
          bookingId: data.newBookingId,
        },
      };

    case 'ScheduledBooking':
      return {
        name: 'Booking Details',
        params: {
          bookingId: id,
        },
      };

    case 'EngineRun':
      return {
        name: 'Roster',
        params: {
          engineRunId: id,
        },
      };

    case 'FormSubmissionRequest':
      return `${NEW_FORM_SUBMISSION}/requests`;

    default:
      return undefined;
  }
}

export { getRouteObject };
